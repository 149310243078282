import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'

var CompanyId='';

class Table extends React.Component {

   onChange_f_applicantname(Value){ var c = this.state.student; c.f_applicantname = Value.target.value; this.setState({ student: c })}
   onChange_f_dob(Value){ var c = this.state.student; c.f_dob = Value.target.value; this.setState({ student: c })}
   onChange_f_gender(Value){ var c = this.state.student; c.f_gender = Value.target.value; this.setState({ student: c })}
   onChange_f_street(Value){ var c = this.state.student; c.f_street = Value.target.value; this.setState({ student: c })}
   onChange_f_city(Value){ var c = this.state.student; c.f_city = Value.target.value; this.setState({ student: c })}
   onChange_f_province(Value){ var c = this.state.student; c.f_province = Value.target.value; this.setState({ student: c })}
   onChange_f_postalcode(Value){ var c = this.state.student; c.f_postalcode = Value.target.value; this.setState({ student: c })}
   onChange_f_phone(Value){ var c = this.state.student; c.f_phone = Value.target.value; this.setState({ student: c })}
   onChange_f_extension(Value){ var c = this.state.student; c.f_extension = Value.target.value; this.setState({ student: c })}
   onChange_f_sin(Value){ var c = this.state.student; c.f_sin = Value.target.value; this.setState({ student: c })}
   onChange_f_emergencycontact(Value){ var c = this.state.student; c.f_emergencycontact = Value.target.value; this.setState({ student: c })}
   onChange_f_emergencyphone(Value){ var c = this.state.student; c.f_emergencyphone = Value.target.value; this.setState({ student: c })}
   onChange_f_majorintersection1(Value){ var c = this.state.student; c.f_majorintersection1 = Value.target.value; this.setState({ student: c })}
   onChange_f_majorintersection2(Value){ var c = this.state.student; c.f_majorintersection2 = Value.target.value; this.setState({ student: c })}
   onChange_f_vaccinestatus(Value){ var c = this.state.student; c.f_vaccinestatus = Value.target.value; this.setState({ student: c })}
   onChange_f_positionsapplying(Value,d){ var c = this.state.student; 
        var d=Value.target.value
        if (c.f_positionsapplying.includes(d)){
            c.f_positionsapplying = c.f_positionsapplying.replace(d,'')
        } else {
            c.f_positionsapplying = c.f_positionsapplying + d  + ','
        }
        this.setState({ student: c })
    }
   onChange_f_expectedminsalary(Value){ var c = this.state.student; c.f_expectedminsalary = Value.target.value; this.setState({ student: c })}   
   onChange_f_expectedmaxsalary(Value){ var c = this.state.student; c.f_expectedmaxsalary = Value.target.value; this.setState({ student: c })}   
   onChange_f_preferredshifts(Value,d){ var c = this.state.student; 
    var d=Value.target.value
    if (c.f_preferredshifts.includes(d)){
        c.f_preferredshifts = c.f_preferredshifts.replace(d,'')
    } else {
        c.f_preferredshifts = c.f_preferredshifts + d  + ','
    }
    this.setState({ student: c })
    }
    onChange_f_preferreddayofweek(Value,d){ var c = this.state.student; 
        var d=Value.target.value
        if (c.f_preferreddayofweek.includes(d)){
            c.f_preferreddayofweek = c.f_preferreddayofweek.replace(d,'')
        } else {
            c.f_preferreddayofweek = c.f_preferreddayofweek + d  + ','
        }
        this.setState({ student: c })
    }
    onChange_f_transportation(Value){ var c = this.state.student; c.f_transportation = Value.target.value; this.setState({ student: c })}   
    onChange_f_permit(Value){ var c = this.state.student; c.f_permit = !c.f_permit; this.setState({ student: c })}   
    onChange_f_safetyshoes(Value){ var c = this.state.student; c.f_safetyshoes = !c.f_safetyshoes; this.setState({ student: c })}   
    onChange_f_safetyglasses(Value){ var c = this.state.student; c.f_safetyglasses = !c.f_safetyglasses; this.setState({ student: c })}   
    onChange_f_hardhat(Value){ var c = this.state.student; c.f_hardhat = !c.f_hardhat; this.setState({ student: c })}   
    onChange_f_safetyvest(Value){ var c = this.state.student; c.f_safetyvest = !c.f_safetyvest; this.setState({ student: c })}   
    onChange_f_safetygloves(Value){ var c = this.state.student; c.f_safetygloves = !c.f_safetygloves; this.setState({ student: c })}   
    onChange_f_education(Value){ var c = this.state.student; c.f_education = Value.target.value; this.setState({ student: c })}   

    onChange_f_employername(Value){ var c = this.state.student; c.f_employername = Value.target.value; this.setState({ student: c })}   
    onChange_f_positionshheld(Value){ var c = this.state.student; c.f_positionshheld = Value.target.value; this.setState({ student: c })}   
    onChange_f_salary(Value){ var c = this.state.student; c.f_salary = Value.target.value; this.setState({ student: c })}   
    onChange_f_joinedon(Value){ var c = this.state.student; c.f_joinedon = Value.target.value; this.setState({ student: c })}   
    onChange_f_manager(Value){ var c = this.state.student; c.f_manager = Value.target.value; this.setState({ student: c })}   
    onChange_f_contactphone(Value){ var c = this.state.student; c.f_employerphone = Value.target.value; this.setState({ student: c })}   
    onChange_f_reasonforleaving(Value){ var c = this.state.student; c.f_reasonforleaving = Value.target.value; this.setState({ student: c })}   
    onChange_f_contactemployer(Value){ var c = this.state.student; c.f_contactemployer = !c.f_contactemployer; this.setState({ student: c })}   

    onChange_f_employername1(Value){ var c = this.state.student1; c.f_employername = Value.target.value; this.setState({ student1: c })}   
    onChange_f_positionshheld1(Value){ var c = this.state.student1; c.f_positionshheld = Value.target.value; this.setState({ student1: c })}   
    onChange_f_salary1(Value){ var c = this.state.student1; c.f_salary = Value.target.value; this.setState({ student1: c })}   
    onChange_f_joinedon1(Value){ var c = this.state.student1; c.f_joinedon = Value.target.value; this.setState({ student1: c })}   
    onChange_f_manager1(Value){ var c = this.state.student1; c.f_manager = Value.target.value; this.setState({ student1: c })}   
    onChange_f_contactphone1(Value){ var c = this.state.student1; c.f_employerphone = Value.target.value; this.setState({ student1: c })}   
    onChange_f_reasonforleaving1(Value){ var c = this.state.student1; c.f_reasonforleaving = Value.target.value; this.setState({ student1: c })}   
    onChange_f_contactemployer1(Value){ var c = this.state.student1; c.f_contactemployer = !c.f_contactemployer; this.setState({ student1: c })}   

    onChange_f_employername2(Value){ var c = this.state.student2; c.f_employername = Value.target.value; this.setState({ student2: c })}   
    onChange_f_positionshheld2(Value){ var c = this.state.student2; c.f_positionshheld = Value.target.value; this.setState({ student2: c })}   
    onChange_f_salary2(Value){ var c = this.state.student2; c.f_salary = Value.target.value; this.setState({ student2: c })}   
    onChange_f_joinedon2(Value){ var c = this.state.student2; c.f_joinedon = Value.target.value; this.setState({ student2: c })}   
    onChange_f_manager2(Value){ var c = this.state.student2; c.f_manager = Value.target.value; this.setState({ student2: c })}   
    onChange_f_contactphone2(Value){ var c = this.state.student2; c.f_employerphone = Value.target.value; this.setState({ student2: c })}   
    onChange_f_reasonforleaving2(Value){ var c = this.state.student2; c.f_reasonforleaving = Value.target.value; this.setState({ student2: c })}   
    onChange_f_contactemployer2(Value){ var c = this.state.student2; c.f_contactemployer = !c.f_contactemployer; this.setState({ student2: c })}   


   constructor(props) {
      super(props)
      console.log(this.props.match.params.id)
      this.state = {
         student: 
            { id: 0, name: '',  f_email: '' , f_positionsapplying:'', f_preferredshifts:'', f_preferreddayofweek:''},
            student1:{},
            student2:{}
         
      }
   }
   
   async getUser(){
      var topost = {
          id:this.props.match.params.id
      };
      axios.post('https://dev.connectjob.ca/appview.php', JSON.stringify(topost)).then(response => {
         console.log('Got Result',response);
         if (response.data.Error && response.data.Error.length > 0) {
             Swal.fire({
                 title: 'Error!',
                 text: 'Could Not Retrieve Data',
                 icon: 'error'
             })
         } else {
             if (response.data.length>0 && response.data[0].f_email){
                this.setState({
                    student:response.data[0]
                 },()=>{console.log(this.state)})
                 if(response.data[1]){
                    this.setState({
                        student1:response.data[1]
                     },()=>{console.log(this.state)})
    
                 }
                 if(response.data[2]){
                    this.setState({
                        student2:response.data[2]
                     },()=>{console.log(this.state)})
                 }
             }
      
         }

     })


/*      let usersData=await axios.post('https://dev.connectjob.ca/applicantlist.php')
                      .then(res=>{
                         console.log(res.data)
                        return res.data.data;
                      })
                      .catch(err=>{
                        console.log(err);
                      })
                      console.log('Got',usersData)
      this.setState({
         students:usersData
      },()=>{console.log(this.state)})*/
    }
    componentDidMount(){
      //console.log('Ari Here');
      this.getUser();
    }

    onChangeApplicantname(Value) {
        var c = this.state.student
        c.f_applicantname = Value.target.value;
        this.setState({ student: c })
    }
    onChange_f_email(Value){
        var c = this.state.student
        c.f_email = Value.target.value;
        this.setState({ student: c })
    }
    onChange_f_salutation(Value){
        var c = this.state.student
        c.f_salutation = Value.target.value;
        this.setState({ student: c })
    }

    UpdateApplicant() 
    {
        var topost = JSON.parse(JSON.stringify(this.state.student))
        for (var key in this.state.student1) {
            topost[key+"2"] = this.state.student1[key];
        }
        for (var key in this.state.student2) {
            topost[key+"3"] = this.state.student2[key];
        }
        topost['f_applicantid']=this.props.match.params.id
        console.log(JSON.stringify(topost));
           
    
            axios.post('https://dev.connectjob.ca/updateapplicant.php', JSON.stringify(topost)).then(response => {
                console.log(response);
                if (response.data.Error && response.data.Error.length > 0) {
                    Swal.fire({  
                        position: "bottom",
                        icon: 'error',  
                        title: 'Oops...',  
                        text: 'Failed to save!'  
                      });
                } else {
                    Swal.fire({  
                        title: 'Success',  
                        type: 'success',  
                        position: "bottom",
                        text: 'Application has been saved.',  
                      });              }
    
            })
    
        }
    


   render() {
      return (
        <div class="row" style={{ margin: 20 }}>
        <div class="col-md-12 ">

            <form class="form-horizontal" role="form">
                <h1 style={{ textAlign: "center" }}>Applicant Edit</h1>

                <div class="form-group">
                    <div class="col-sm-4">
                        <label for="Email">Email</label>
                        <input  type="email" id="email"  name="email" placeholder="You will use your email address to login" class="form-control"  value={this.state.student.f_email}
                        onChange={this.onChange_f_email.bind(this)}
                        />

                      
                            
                    </div>
                </div>
                <hr />

                <div class="form-group">
                    <div class="col-sm-3">
                        <label for="Salutation">Salutation</label>
                        <input type="text" name="Salutation" id="Salutation" class="form-control" value={this.state.student.f_salutation}
                        onChange={this.onChange_f_salutation.bind(this)}
                        />

                   
</div>
                    <div class="col-sm-3">
                        <label for="ApplicantName">Applicant Name</label>
                        <input   type="text" name="ApplicantName" id="ApplicantName"
                        value = {this.state.student.f_applicantname}
                        onChange={this.onChange_f_applicantname.bind(this)}
                         class="form-control" />
                   
                    </div>
                    <div class="col-sm-3">
                        <label for="DateofBirth">Date Of Birth <span style={{ color: '#31708f' }}>*Must be 18</span></label>
                        <input  type="date" name="DateofBirth" id="DateofBirth" class="form-control" value={this.state.student.f_dob}
                        onChange={this.onChange_f_dob.bind(this)}
                        />

                  
</div>
                    <div class="col-sm-3">
                        <label for="gender">Gender</label>
                        <select  id="gender" placeholder="Select A Gender" class="form-control" value={this.state.student.f_gender}
                        onChange={this.onChange_f_gender.bind(this)}
                         >
                            <option value="">Select A Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>

                        </select>

                    </div>
                </div>

                <div class="form-group">
                    <div class="col-sm-3">
                        <label for="StreetAddr">Street Address</label>
                        <input type="text" name="StreetAddr" id="StreetAddr" class="form-control" value={this.state.student.f_street}
                        onChange={this.onChange_f_street.bind(this)}
                        />

                    </div>
                    <div class="col-sm-3">
                        <label for="city">City</label>
                        <input type="text" name="city" id="city" class="form-control" value={this.state.student.f_city}
                        onChange={this.onChange_f_city.bind(this)}
                        />

                    </div>
                    <div class="col-sm-3">
                        <label for="Province">Province</label>
                        <select  id="Province" placeholder="Select A Province" class="form-control" value={this.state.student.f_province}
                        onChange={this.onChange_f_province.bind(this)}
                        >
                            <option value="">Select A Province</option>
                            <option value="Ontario">Ontario</option>
                            <option value="Quebec">Quebec</option>
                            <option value="Nova_Scotia<">Nova Scotia</option>
                            <option value="New_Brunswick">New Brunswick</option>
                            <option value="Manitoba">Manitoba</option>
                            <option value="British_Columbia">British Columbia</option>
                            <option value="Prince_Edward_Island">Prince Edward Island</option>
                            <option value="Saskatchewan">Saskatchewan</option>
                            <option value="Alberta">Alberta</option>
                            <option value="Newfoundland_and_Labrador">Newfoundland and Labrador</option>
                            <option value="Northwest_Territories">Northwest Territories</option>
                            <option value="Yukon">Yukon</option>
                            <option value="Nunavut">Nunavut</option>

                        </select>

                    </div>
                    <div class="col-sm-3">
                        <label for="PostalCode">Postal Code</label>
                        <input  type="text" name="PostalCode" id="PostalCode" class="form-control" value={this.state.student.f_postalcode}
                        onChange={this.onChange_f_postalcode.bind(this)}
                        />

                    </div>
                </div>
                <hr />

                <div class="form-group">
                    <div class="col-sm-3">
                        <label for="PhoneNumber">Phone Number</label>
                        <input type="tel" name="PhoneNumber" id="PhoneNumber" class="form-control" placeholder="(905) 333 4535" value={this.state.student.f_phone}
                        onChange={this.onChange_f_phone.bind(this)}
                        />
                  
</div>
                    <div class="col-sm-3">
                        <label for="Extension">Extension</label>
                        <input type="text" name="Extension" id="Extension" class="form-control" value={this.state.student.f_extension}
                        onChange={this.onChange_f_extension.bind(this)}
                        />

                    </div>
                    <div class="col-sm-3">
                        <label for="SIN">SIN(Optional)</label>
                        <input  type="text" name="SIN" id="SIN" class="form-control" value={this.state.student.f_sin}
                        onChange={this.onChange_f_sin.bind(this)}
                        />
                    </div>
<div class="col-sm-3">
                        <label for="EmergencyContactName">Emergency Contact Name</label>
                        <input type="text" name="EmergencyContactName" id="EmergencyContactName" class="form-control" value={this.state.student.f_emergencycontact}
                        onChange={this.onChange_f_emergencycontact.bind(this)}
                        />
                    </div>

                </div>
                <div class="form-group">
                  
                    <div class="col-sm-3">
                        <label for="EmergencyContactNumber">Emergency Contact Phone Number</label>
                        <input  type="tel" name="EmergencyContactNumber" id="EmergencyContactNumber" class="form-control" placeholder="(905) 333 4535" value={this.state.student.f_emergencyphone} 
                        onChange={this.onChange_f_emergencyphone.bind(this)}
                        />


                   
</div>
<div class="col-sm-3">
                        <label for="MajorIntersection1">Major Intersection 1</label>
                        <input type="text" name="MajorIntersection1" id="MajorIntersection1" class="form-control" value={this.state.student.f_majorintersection1} 
                        onChange={this.onChange_f_majorintersection1.bind(this)}
                        />

                    </div>
<div class="col-sm-3">
                        <label for="MajorIntersection2">Major Intersection 2</label>
                        <input  type="text" name="MajorIntersection2" id="MajorIntersection2" class="form-control" value={this.state.student.f_majorintersection2}
                        onChange={this.onChange_f_majorintersection2.bind(this)}
                        />

                    </div>
<div class="col-sm-3">
                        <label for="vstatus">Vaccination Status</label>
                        <select id="vstatus" placeholder="Select The Vaccine Status" class="form-control"  value={this.state.student.f_vaccinestatus}
                        onChange={this.onChange_f_vaccinestatus.bind(this)}
                        >
                            <option value="">Select The Vaccine Status</option>
                            <option value="fullBooster">Fully Vaccinated + Booster</option>
                            <option value="full">Fully Vaccinated</option>
<option value="1dose">1 Dose</option>
<option value="0dose">0 Dose</option>



                        </select>
<h5 style={{ color: '#4c4cca', font: 12 }}> Documentation Should be provided upon employers request.</h5>


                    </div>
                 
                </div>


                <hr />
                <div class="form-group">
                    <div class="col-sm-4">
                        <label for="Positions">Positions Applying For</label><br />
                        <div class="positions" >
                            <input type="checkbox" name="positions[]" value="Administrative Support"  
                                checked={this.state.student.f_positionsapplying.includes('Administrative Support') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                            /> Administrative Support<br />
                            <input type="checkbox" name="positions[]" value="Clerical"
                                checked={this.state.student.f_positionsapplying.includes('Clerical') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                                                /> Clerical<br />
                            <input type="checkbox" name="positions[]" value="General Office Support"
                                checked={this.state.student.f_positionsapplying.includes('General Office Support') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                /> General Office Support<br />
                            <input type="checkbox" name="positions[]" value="Human Resource Professionals" 
                                checked={this.state.student.f_positionsapplying.includes('Human Resource Professionals') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                /> Human Resource Professionals<br />
                            <input type="checkbox" name="positions[]" value="Marketing"
                                checked={this.state.student.f_positionsapplying.includes('Marketing') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                /> Marketing<br />
                            <input type="checkbox" name="positions[]" value="Reception" 
                                checked={this.state.student.f_positionsapplying.includes('Reception') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                /> Reception<br />
                            <input type="checkbox" name="positions[]" value="Call Center" 
                                checked={this.state.student.f_positionsapplying.includes('Call Center') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                /> Call Center<br />
                            <input type="checkbox" name="positions[]" value="Telemarketing" 
                                checked={this.state.student.f_positionsapplying.includes('Telemarketing') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                 /> Telemarketing
                        <br />
                            <input type="checkbox" name="positions[]" value="Information Technology"  
                                checked={this.state.student.f_positionsapplying.includes('Information Technology') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                /> Information Technology<br />
                            <input type="checkbox" name="positions[]" value="Hospitality"  
                                checked={this.state.student.f_positionsapplying.includes('Hospitality') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                /> Hospitality<br />
                            <input type="checkbox" name="positions[]" value="Accounting"  
                                checked={this.state.student.f_positionsapplying.includes('Accounting') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                /> Accounting<br />
                            <input type="checkbox" name="positions[]" value="Bookkeeping"  
                                checked={this.state.student.f_positionsapplying.includes('Bookkeeping') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                /> Bookkeeping<br />
                            <input type="checkbox" name="positions[]" value="Collections"  
                                checked={this.state.student.f_positionsapplying.includes('Collections') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                /> Collections<br />
                            <input type="checkbox" name="positions[]" value="Payroll Processing and Support" 
                                checked={this.state.student.f_positionsapplying.includes('Payroll Processing and Support') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                 /> Payroll Processing and Support<br />
                            <input type="checkbox" name="positions[]" value="File Clerks"  
                                checked={this.state.student.f_positionsapplying.includes('File Clerks') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                /> File Clerks<br />
                            <input type="checkbox" name="positions[]" value="Legal Secretary"  
                                checked={this.state.student.f_positionsapplying.includes('Legal Secretary') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                />Legal Secretary<br />
                            <input type="checkbox" name="positions[]" value="Paralegal" 
                                checked={this.state.student.f_positionsapplying.includes('Paralegal') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                 />Paralegal<br />
                            <input type="checkbox" name="positions[]" value="RN" 
                                checked={this.state.student.f_positionsapplying.includes('RN') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                 /> RN<br />
                            <input type="checkbox" name="positions[]" value="RPN"  
                                checked={this.state.student.f_positionsapplying.includes('RPN') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                />RPN<br />
                            <input type="checkbox" name="positions[]" value="PSW" 
                                checked={this.state.student.f_positionsapplying.includes('PSW') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                 /> PSW<br />
                            <input type="checkbox" name="positions[]" value="General Labour"  
                                checked={this.state.student.f_positionsapplying.includes('General Labour') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                />General Labour<br />
                            <input type="checkbox" name="positions[]" value="Warehouse"  
                                checked={this.state.student.f_positionsapplying.includes('Warehouse') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                />Warehouse<br />
                            <input type="checkbox" name="positions[]" value="Loading/Unloading"  
                                checked={this.state.student.f_positionsapplying.includes('Loading/Unloading') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                /> Loading/Unloading<br />
                            <input type="checkbox" name="positions[]" value="Packing"
                                 checked={this.state.student.f_positionsapplying.includes('Packing') }
                                 onChange={this.onChange_f_positionsapplying.bind(this)}
                                 />Packing<br />
                            <input type="checkbox" name="positions[]" value="Shipping/Receiving"  
                                checked={this.state.student.f_positionsapplying.includes('Shipping/Receiving') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                /> Shipping/Receiving<br />
                            <input type="checkbox" name="positions[]" value="Inventory"  
                                checked={this.state.student.f_positionsapplying.includes('Inventory') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                /> Inventory<br />
                            <input type="checkbox" name="positions[]" value="Assembly line"  
                                checked={this.state.student.f_positionsapplying.includes('Assembly line') }
                                />Assembly line<br />
                            <input type="checkbox" name="positions[]" value="Sanitation"  
                                checked={this.state.student.f_positionsapplying.includes('Sanitation') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                />Sanitation<br />
                            <input type="checkbox" name="positions[]" value="Order Picker"  
                                checked={this.state.student.f_positionsapplying.includes('Order Picker') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                />Order Picker<br />
                            <input type="checkbox" name="positions[]" value="AZ, DZ, G Driver"  
                                checked={this.state.student.f_positionsapplying.includes('AZ, DZ, G Driver') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                />AZ, DZ, G Driver<br />
                            <input type="checkbox" name="positions[]" value="Forklift Operators"  
                                checked={this.state.student.f_positionsapplying.includes('Forklift Operators') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                />Forklift Operators<br />
                            <input type="checkbox" name="positions[]" value="Machine Operators"  
                                checked={this.state.student.f_positionsapplying.includes('Machine Operators') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                /> Machine Operators<br />
                            <input type="checkbox" name="positions[]" value="CNC Programmers/Operators"  
                                checked={this.state.student.f_positionsapplying.includes('CNC Programmers/Operators') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                /> CNC Programmers/Operators<br />
                            <input type="checkbox" name="positions[]" value="Welders"  
                                checked={this.state.student.f_positionsapplying.includes('Welders') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                />Welders<br />
                            <input type="checkbox" name="positions[]" value="Quality control"  
                                checked={this.state.student.f_positionsapplying.includes('Quality control') }
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                />Quality control<br />

                            <input type="checkbox" name="positions[]" value="Other"  
                                onChange={this.onChange_f_positionsapplying.bind(this)}
                                checked={this.state.student.f_positionsapplying.includes('Other') }
                                />Other<br />
                        </div>
                    </div>
                    <div class="col-sm-8" >
                        <h5 style={{ color: '#4c4cca', font: 14 }}> Expected Earnings (Hourly)</h5>
                        <div class="col-sm-3">
                            <label for="minimum">Minimum</label>
                            <input   type="text" name="minimum" id="minimum" placeholder="ie. $20.00/hour" class="form-control"  value={this.state.student.f_expectedminsalary}
                            onChange={this.onChange_f_expectedminsalary.bind(this)}
                                />
                        </div>
                        <div class="col-sm-3">
                            <label for="Expected">Expected</label>
                            <input  type="text" name="Expected" id="Expected" placeholder="ie. $20.00/hour" class="form-control" value={this.state.student.f_expectedmaxsalary}
                            onChange={this.onChange_f_expectedmaxsalary.bind(this)}
                            />
                        </div>
                        <div class="col-sm-6">
                            <label for="Timings">Preferred Timings</label><br />
                            <input type="checkbox" name="timings[]" value="Mornings"  
                                checked={this.state.student.f_preferredshifts.includes('Mornings') }
                                onChange={this.onChange_f_preferredshifts.bind(this)}
                            /> Mornings<br />
                            <input type="checkbox" name="timings[]" value="Afternoons"   
                                checked={this.state.student.f_preferredshifts.includes('Afternoons') }
                                onChange={this.onChange_f_preferredshifts.bind(this)}
                            /> Afternoons<br />
                            <input type="checkbox" name="timings[]" value="Evenings" 
                                checked={this.state.student.f_preferredshifts.includes('Evenings') }
                                onChange={this.onChange_f_preferredshifts.bind(this)}
                             /> Evenings<br />
                            <input type="checkbox" name="timings[]" value="Nights" 
                                checked={this.state.student.f_preferredshifts.includes('Nights') }
                                onChange={this.onChange_f_preferredshifts.bind(this)}
                             /> Nights<br />

                        </div>
                        <br />

                        <div class="col-sm-3">

                            <input type="checkbox" name="weekday[]" value="Sunday"  
                                checked={this.state.student.f_preferreddayofweek.includes('Sunday') }
                                onChange={this.onChange_f_preferreddayofweek.bind(this)}
                              /> Sunday<br />
                            <input type="checkbox" name="weekday[]" value="Monday"  
                                checked={this.state.student.f_preferreddayofweek.includes('Monday') }
                                onChange={this.onChange_f_preferreddayofweek.bind(this)}
                             /> Monday<br />
                            <input type="checkbox" name="weekday[]" value="Tuesday"  
                                checked={this.state.student.f_preferreddayofweek.includes('Tuesday') }
                                onChange={this.onChange_f_preferreddayofweek.bind(this)}
                             /> Tuesday<br />
                            <input type="checkbox" name="weekday[]" value="Wednesday"  
                                checked={this.state.student.f_preferreddayofweek.includes('Wednesday') }
                                onChange={this.onChange_f_preferreddayofweek.bind(this)}
                             /> Wednesday<br />


                        </div>
                        <div class="col-sm-3">

                            <input type="checkbox" name="weekday[]" value="Thursday"   
                                checked={this.state.student.f_preferreddayofweek.includes('Thursday') }
                                onChange={this.onChange_f_preferreddayofweek.bind(this)}
                             /> Thursday<br />
                            <input type="checkbox" name="weekday[]" value="Friday"  
                                checked={this.state.student.f_preferreddayofweek.includes('Friday') }
                                onChange={this.onChange_f_preferreddayofweek.bind(this)}
                             /> Friday<br />
                            <input type="checkbox" name="weekday[]" value="Saturday"  
                                checked={this.state.student.f_preferreddayofweek.includes('Saturday') }
                                onChange={this.onChange_f_preferreddayofweek.bind(this)}
                             /> Saturday<br />



                        </div>
                        <div class="col-sm-6">
                            <label for="Transportation">Method Of Transportation</label>
                            <input type="text" name="Transportation" id="Transportation" class="form-control" value={this.state.student.f_transportation}
                            onChange={this.onChange_f_transportation.bind(this)}
                            />
                        </div>
                    </div>

                </div>
                <div class="form-group">
                    <div class="col-sm-4">
<fieldset className="sin" >
                        <label>Question applies to those that do not have a SIN: Do you have a valid work permit, permitting you to legally work in Canada?</label>
                        <input type="radio" name="sin"  id="Yes"   checked={this.state.student.f_permit==1}
                        onChange={this.onChange_f_permit.bind(this)}
                        /> Yes &nbsp;&nbsp;
                    <input type="radio" name="sin"  id="No"  checked={this.state.student.f_permit!=1}
                        onChange={this.onChange_f_permit.bind(this)}
                        /> No<br />
</fieldset>
                        <label style={{ color: '#4c4cca', font: 14 }}>If applying for manufacturing positions (general labour etc.) do you have the following safety equipment:</label>
                        <label>Safety Shoes</label>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;   <input type="radio" name="safety_shoes" checked={this.state.student.f_safetyshoes==1} id="Yes" 
                        onChange={this.onChange_f_safetyshoes.bind(this)}
                        />  Yes &nbsp;&nbsp;
                    <input type="radio" name="safety_shoes"  id="No" checked={this.state.student.f_safetyshoes!=1}  
                    onChange={this.onChange_f_safetyshoes.bind(this)}
                    /> No<br />
                    
                        <label>Safety Glasses</label>&nbsp;&nbsp;&nbsp;    <input type="radio" name="safety_glasses"  checked={this.state.student.f_safetyglasses==1}  id="Yes" 
                        onChange={this.onChange_f_safetyglasses.bind(this)}
                        />  Yes &nbsp;&nbsp;
                    <input type="radio" name="safety_glasses"  id="No"    checked={this.state.student.f_safetyglasses!=1}  
                    onChange={this.onChange_f_safetyglasses.bind(this)}
                    /> No<br />
                    
                        <label>Hard Hat</label> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;<input type="radio" name="equipment" checked={this.state.student.f_hardhat==1}  id="Yes" 
                        onChange={this.onChange_f_hardhat.bind(this)}
                    />  Yes &nbsp;&nbsp;
                    <input  checked={this.state.student.f_hardhat!=1}  type="radio" name="equipment"   id="No"  
                    onChange={this.onChange_f_hardhat.bind(this)}
                    /> No<br />
                    
                        <label>Safety vest</label>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <input type="radio" name="safety_vest" checked={this.state.student.f_safetyvest==1} id="Yes"
                        onChange={this.onChange_f_safetyvest.bind(this)}
                         />  Yes &nbsp;&nbsp;
                    <input type="radio"  checked={this.state.student.f_safetyvest!=1} name="safety_vest"  id="No"
                        onChange={this.onChange_f_safetyvest.bind(this)}

                     /> No<br />

                        <label>Safety gloves</label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="radio" name="safety_gloves" checked={this.state.student.f_safetygloves==1}  id="Yes" 
                        onChange={this.onChange_f_safetygloves.bind(this)}
                        />  Yes &nbsp;&nbsp;
                    <input type="radio" name="safety_gloves"   checked={this.state.student.f_safetygloves!=1} id="No" 
                        onChange={this.onChange_f_safetygloves.bind(this)}
                        /> No<br />



                    </div>
                    <div class="col-sm-8">
                        <label for="Education">Education</label>
                        <textarea  type="text" name="Education" id="Education" class="form-control" placeholder="Enter Your Education Details -Max 300 Characters" value={this.state.student.f_education}
                        onChange={this.onChange_f_education.bind(this)}
                        
                        ></textarea>
                    </div>

                </div>
                <div class="bg" >
                    <h4 style={{ color: '#4c4cca' }}>Employment History (List Most Recent Employer First) * Atleast One Employment Record is required</h4>
                    <div class="form-group" >
                        <div class="col-sm-4">
                            <div class="circle">1</div><br />

                            <label for="EmployerName">Employer Name</label>
                            <input type="text" name="EmployerName1" id="EmployerName1" class="form-control" value={this.state.student.f_employername}
                                onChange={this.onChange_f_employername.bind(this)}
                            /><br />
                            <label for="PositionHeld">Position(s) Held</label>
                            <input  type="text" name="PositionHeld1" id="PositionHeld1" class="form-control" value={this.state.student.f_positionshheld}
                                onChange={this.onChange_f_positionshheld.bind(this)}
                                /><br />
                            <label for="Salary">Salary</label>
                            <input     type="text" name="Salary1" id="Salary" class="form-control" value={this.state.student.f_salary}
                            onChange={this.onChange_f_salary.bind(this)}
                            /><br />
                            <label for="Employed From / To">Employed From / To</label>
                            <input type="text" name="Employed_From_To1" id="Employed_From_To1" class="form-control" value={this.state.student.f_joinedon}
                                onChange={this.onChange_f_joinedon.bind(this)}
                            /><br />
                            <label for="ManagerName">Manager's Name</label>
                            <input  type="text" name="ManagerName1" id="ManagerName1" class="form-control" value={this.state.student.f_manager}
                                onChange={this.onChange_f_manager.bind(this)}
                                /><br />
                            <label for="Contact Number">Contact Number</label>
                            <input  type="text" name="ContactNumber1" id="ContactNumber1" class="form-control" value={this.state.student.f_employerphone}
                            onChange={this.onChange_f_contactphone.bind(this)}
                            /><br />
                            <label for="Leaving">Reason For Leaving</label>
                            <textarea  type="text" name="Leaving1" id="Leaving1" class="form-control" placeholder="Max 75 Characters" value={this.state.student.f_reasonforleaving}
                            onChange={this.onChange_f_reasonforleaving.bind(this)}
                            ></textarea><br />
                            <input   checked={this.state.student.f_contactemployer} type="checkbox" 
                            onChange={this.onChange_f_contactemployer.bind(this)}
                             /> May We Contact The Employer?<br />


                        </div>
                        <div class="col-sm-4">
                            <div class="circle">2</div><br />

                            <label for="EmployerName">Employer Name</label>
                            <input type="text" name="EmployerName2" id="EmployerName2" class="form-control" value={this.state.student1.f_employername}
                                onChange={this.onChange_f_employername1.bind(this)}
                             /><br />
                            <label for="PositionHeld">Position(s) Held</label>
                            <input  type="text" name="PositionHeld2" id="PositionHeld2" class="form-control" value={this.state.student1.f_positionshheld}
                                onChange={this.onChange_f_positionshheld1.bind(this)}
                            /><br />
                            <label for="Salary">Salary</label>
                            <input  type="text" name="Salary2" id="Salary2" class="form-control"  value={this.state.student1.f_salary}
                                onChange={this.onChange_f_salary1.bind(this)}
                            /><br />
                            <label for="Employed From / To">Employed From / To</label>
                            <input type="text" name="Employed_From_To2" id="Employed_From_To2" class="form-control"  value={this.state.student1.f_joinedon} 
                                onChange={this.onChange_f_joinedon1.bind(this)}
                            /><br />
                            <label for="ManagerName">Manager's Name</label>
                            <input  type="text" name="ManagerName2" id="ManagerName2" class="form-control"  value={this.state.student1.f_manager}
                                onChange={this.onChange_f_manager1.bind(this)}
                            /><br />
                            <label for="Contact Number">Contact Number</label>
                            <input  type="text" name="ContactNumber2" id="ContactNumber2" class="form-control"  value={this.state.student1.f_employerphone}
                                onChange={this.onChange_f_contactphone1.bind(this)}
                            /><br />
                            <label for="Leaving">Reason For Leaving</label>
                            <textarea  type="text" name="Leaving2" id="Leaving2" class="form-control" placeholder="Max 75 Characters" value={this.state.student1.f_reasonforleaving}
                            onChange={this.onChange_f_reasonforleaving1.bind(this)}
                            ></textarea><br />
                            <input    type="checkbox" checked={this.state.student1.f_contactemployer} 
                            onChange={this.onChange_f_contactemployer1.bind(this)}
                            />May We Contact The Employer?<br />


                        </div>
                        <div class="col-sm-4">
                            <div class="circle">3</div><br />

                            <label for="EmployerName">Employer Name</label>
                            <input type="text" name="EmployerName3" id="EmployerName3" class="form-control" value={this.state.student2.f_employername} 
                                onChange={this.onChange_f_employername2.bind(this)}
                             /><br />
                            <label for="PositionHeld">Position(s) Held</label>
                            <input  type="text" name="PositionHeld3" id="PositionHeld3" class="form-control" value={this.state.student2.f_positionshheld} 
                                onChange={this.onChange_f_positionshheld2.bind(this)}
                            /><br />
                            <label for="Salary">Salary</label>
                            <input  type="text" name="Salary3" id="Salary3" class="form-control"  value={this.state.student2.f_salary}
                                  onChange={this.onChange_f_salary2.bind(this)}
                             /><br />
                            <label for="Employed From / To">Employed From / To</label>
                            <input type="text" name="Employed_From_To3" id="Employed_From_To3" class="form-control"  value={this.state.student2.f_joinedon}
                                    onChange={this.onChange_f_joinedon2.bind(this)}
                            /><br />
                            <label for="ManagerName">Manager's Name</label>
                            <input type="text" name="ManagerName3" id="ManagerName3" class="form-control"  value={this.state.student2.f_manager}
                                onChange={this.onChange_f_manager2.bind(this)}
                            /><br />
                            <label for="Contact Number">Contact Number</label>
                            <input type="text" name="ContactNumber3" id="ContactNumber3" class="form-control"  value={this.state.student2.f_employerphone} 
                                onChange={this.onChange_f_contactphone2.bind(this)}
                            /><br />
                            <label for="Leaving">Reason For Leaving</label>
                            <textarea  type="text" name="Leaving3" id="Leaving3" class="form-control" placeholder="Max 75 Characters" value={this.state.student2.f_reasonforleaving}
                            onChange={this.onChange_f_reasonforleaving2.bind(this)}
                            ></textarea><br />
                            <input  type="checkbox" checked={this.state.student2.f_contactemployer} 
                            onChange={this.onChange_f_contactemployer2.bind(this)}
                            />May We Contact The Employer?<br />


                        </div>

                    </div>

                </div>
                <div class="form-group">
               
                            <div class="col-sm-12">
                                <button type='button' onClick={this.UpdateApplicant.bind(this)} class="btn btn-primary">UPDATE NOW</button>
                            </div><br />



                </div>
              




            </form>
        </div>
    </div>


)
   }
}

export default Table;