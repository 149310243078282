
import React, { useState, Component } from 'react';
import queryString from 'query-string';
import axios from 'axios';
import Swal from "sweetalert2";
import logo from './logo.svg';
import './App.css';
import Customers from './Customers'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
var CompanyId;
const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const validPhoneRegex = RegExp(

    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
);


const validateForm = errors => {
    let valid = true;
    Object.values(errors).forEach(val => val.length > 0 && (valid = false));
    return valid;
};




class RegisterPage extends Component {
    componentDidMount(){
        const value=queryString.parse(window.location.hash);
        CompanyId=Object.values(value)[0];
//        console.log('Id',CompanyId)
            document.title = "Job Portal"
    var topost = {
        id: CompanyId
    }

    console.log('Getting Id', topost)
    axios.post('https://dev.connectjob.ca/getcompid.php', JSON.stringify(topost)).then(response => {
        
        if (response.data.Error && response.data.Error.length > 0) {
            console.log('Error')
        } else {
            if (response.data[0]){
                console.log(response.data[0].h_companyname );
                this.setState({ HostCompanyName: response.data[0].h_companyname })
                this.setState({ HostCompanyURL: response.data[0].h_termsurl })
            }
        }
    }
    )}

    constructor(props) {
        super(props)
        this.state = {
            permit:false,
            safetyshoes: false,
            safetyglasses:false,
            hardhat: false,
            safetyvest: false,
            safetygloves: false,
            age:0,
            vaccinestatus:'1',
            HostCompanyName: '',
            HostCompanyURL:'',
            EMail: '',
 emailstyle: {display:'none'},
            applicantname: '',
namestyle:{display:'none'},
            password: '',
pwdstyle: {display:'none'},
 cpassword: '',
cpwdstyle: {display:'none'},
            salutation: '',
salustyle: {display:'none'},
            gender: '',
genderstyle: {display:'none'},
dateofbirth:'',
dobstyle: {display:'none'},
            phone: '',
phonestyle: {display:'none'},
            street: '',
streetstyle: {display:'none'},
            city: '',
citystyle: {display:'none'},
            province: '',
provincestyle: {display:'none'},
            postalcode: '',
postalstyle: {display:'none'},
            sinno: '',
            majorinersection1: '',
major1style: {display:'none'},
            majorintersection2: '',
major2style: {display:'none'},
            emergencycontactphone: '',
emergencyphonestyle: {display:'none'},
vaccinestyle:{display:'none'},
            extension: '',
extstyle: {display:'none'},
            acknowledgement: false,
//acknowledgement: {display:'none'},
            termsofservice: false,
termsstyle: {display:'block'},

positionapplying: [],

preferredshifts: [],
preferreddayofweek: [],
expectedminsalary : '',
expectedsalary: '',
transportation:'',
legalquestions:false,
safetyquestions:'',
skills:'',
education:'',

companyid1:"1",
companyname1:"",
companyactive1:"",
companyaddress1:"",
companyphone1:"",
companyid2:"2",
companyname2:"",
companyactive2:"",
companyaddress2:"",
companyphone2:"",
companyid3:"3",
companyname3:"",
companyactive3:"",
companyaddress3:"",
companyphone3:""


        }
 this.handleInputChange = this.handleInputChange.bind(this);
 this.handletimingsChange = this.handletimingsChange.bind(this);
  this.handleweekdayChange =this.handleweekdayChange.bind(this);
 this.updateRadioButton = this.updateRadioButton.bind(this);
 this.handlenumber = this.handlenumber.bind(this);

this.handleChangepermit = this.handleChangepermit.bind(this)
this.handleChangesafetyshoes = this.handleChangesafetyshoes.bind(this)
this.handleChangesafetyglass = this.handleChangesafetyglass.bind(this)
this.handleChangehardhat = this.handleChangehardhat.bind(this)
this.handleChangesafetyvest = this.handleChangesafetyvest.bind(this)
this.handleChangesafetygloves = this.handleChangesafetygloves.bind(this)

        this.handleChange = this.handleChange.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
 this.handlecontactemp1 = this.handlecontactemp1.bind(this);
 this.handlecontactemp2 = this.handlecontactemp2.bind(this);
 this.handlecontactemp3 = this.handlecontactemp3.bind(this);
    }

    onChangecompanyid1(Value) { this.setState({ companyid1: Value.target.value })  }
    onChangecompanyname1(Value) { this.setState({ companyname1: Value.target.value })  }
    onChangecompanyactive1(Value) { this.setState({ companyactive1: Value.target.value })  }
    onChangecompanyaddress1(Value) { this.setState({ companyaddress1: Value.target.value })  }
    onChangecompanyphone1(Value) { this.setState({ companyphone1: Value.target.value })  }
    
    onChangecompanyid2(Value) { this.setState({ companyid2: Value.target.value })  }
    onChangecompanyname2(Value) { this.setState({ companyname2: Value.target.value })  }
    onChangecompanyactive2(Value) { this.setState({ companyactive2: Value.target.value })  }
    onChangecompanyaddress2(Value) { this.setState({ companyaddress2: Value.target.value })  }
    onChangecompanyphone2(Value) { this.setState({ companyphone2: Value.target.value })  }
    
    onChangecompanyid3(Value) { this.setState({ companyid3: Value.target.value })  }
    onChangecompanyname3(Value) { this.setState({ companyname3: Value.target.value })  }
    onChangecompanyactive3(Value) { this.setState({ companyactive3: Value.target.value })  }
    onChangecompanyaddress3(Value) { this.setState({companyaddress3: Value.target.value })  }
    onChangecompanyphone3(Value) { this.setState({ companyphone3: Value.target.value })  }
    

    handlenumber(e)
       {
           var charCode;
            if (e.keyCode > 0) {
                charCode = e.which || e.keyCode;
            }
            else if (typeof (e.charCode) != "undefined") {
                charCode = e.which || e.keyCode;
            }
            if (charCode == 46)
                return true
            if (charCode > 31 && (charCode < 48 || charCode > 57))
                return false;
            return true;
       }
updateRadioButton(Value) {
 

    this.setState({ legalquestions: Value.target.value });

}


    
handlecontactemp1() {
        this.setState({
            contactemployer1: !this.state.contactemployer1
        })


    }
 handlecontactemp2() {
        this.setState({
            contactemployer2: !this.state.contactemployer2
        })


    }
handlecontactemp3() {
        this.setState({
            contactemployer3: !this.state.contactemployer3
        })


    }





    handleInputChange(event) {
         const target = event.target;
        var value = target.value;

        if (event.target.checked) {
           // this.state.positionapplying[value] = value;

this.state.positionapplying.push(value);

        } else {
            this.state.positionapplying.splice(value, 1);
        }
 console.log(this.state.positionapplying.join(", "));

//this.setState({mystate: this.state.mystate.push.apply(this.state.mystate, json)})


    }

 handletimingsChange(event) {

        const target = event.target;
        var value = target.value;

        if (event.target.checked) {
           // this.state.timings[value] = value;
this.state.preferredshifts.push(value);

        } else {
            this.state.preferredshifts.splice(value, 1);
        }


    }
handleweekdayChange(event) {

        const target = event.target;
        var value = target.value;

        if (event.target.checked) {
           // this.state.timings[value] = value;
this.state.preferreddayofweek.push(value);

        } else {
            this.state.preferreddayofweek.splice(value, 1);
        }


    }

handleChangepermit(event){ this.setState({permit : event.target.checked}) }
handleChangesafetyshoes(event){ this.setState({safetyshoes : event.target.checked}) }
handleChangesafetyglass(event){ this.setState({safetyglasses : event.target.checked}) }
handleChangehardhat(event){ this.setState({hardhat : event.target.checked}) }
handleChangesafetyvest(event){ this.setState({safetyvest : event.target.checked}) }
handleChangesafetygloves(event){ this.setState({safetygloves : event.target.checked}) }


handleChange() {
    this.setState({
        acknowledgement: !this.state.acknowledgement
        })
    console.log(this.state.acknowledgement)
        this.setState({ termsstyle: {
        display:  (this.state.acknowledgement ==true && !this.state.termsofservice ==true) ? 'none' :'block'
        }})
    }


handleCheck() {
    this.setState({
        termsofservice: !this.state.termsofservice
        })
    console.log(this.state.termsofservice)
        this.setState({ termsstyle: {
            display:  (this.state.acknowledgement ==true && !this.state.termsofservice ==true) ? 'none' :'block'
        }})
}
    onChangeMail(Value) {
        this.setState({ EMail: Value.target.value})

        var topost = {
            email: Value.target.value
        }
        axios.post('https://dev.connectjob.ca/isunique.php', JSON.stringify(topost)).then(response => {
            
            if (response.data.Error && response.data.Error.length > 0) {
                console.log('Error')
                Swal.fire({  
                    position: "top",
                    icon: 'error',  
                    title: 'Oops...',  
                    text: 'This email could not be verified!'  
                  });
            
                this.setState({ EMail: ''})
            } else {
                if (response.data.Result && response.data.Result=="Fail"){
                    Swal.fire({  
                        position: "top",
                        icon: 'error',  
                        title: 'Oops...',  
                        text: 'This email is already registered!'  
                      }).then(function() {
                        window.location.reload();
                    });
                }
            }
        })

        this.setState({ emailstyle: {
            display: Value.target.value.length <1 ? 'block' :'none'
        }})
this.setState({ emailstyle: {
            display: (!validEmailRegex.test(this.state.EMail))  ? 'block' :'none'
        }})
    }
    onChangepassword(Value) {

       this.setState({ password: Value.target.value })
  this.setState({ pwdstyle: {
            display: Value.target.value.length < 7 ? 'block' :'none'
        }})

       
    }
 onChangecpassword(Value) {

       this.setState({ cpassword: Value.target.value })
  this.setState({ cpwdstyle: {
            display: (this.state.password ===  Value.target.value) ? 'none' :'block'
        }})
    }
    onChangeApplicantname(Value) {
     
       
       this.setState({ applicantname: Value.target.value })
this.setState({ namestyle: {
            display: Value.target.value.length < 1 ? 'block' :'none'
        }})
this.setState({ namestyle: {
            display: (!Value.target.value) ? 'block' :'none'
        }})

     
    }
    onChangesalutation(Value) {
        this.setState({ salutation: Value.target.value })
    }
    onChangegender(Value) {

      this.setState({ gender: Value.target.value })
this.setState({ genderstyle: {
            display: (Value.target.value == '') ? 'block' :'none'
        }})


        
    }
    onChangephone(Value) {

       this.setState({ phone: Value.target.value })




        
    }
    onChangestreet(Value) {
        this.setState({ street: Value.target.value })

      
    
    
    }
    onChangecity(Value) {
        this.setState({ city: Value.target.value })

      
    }
    onChangeprovince(Value) {

       this.setState({ province: Value.target.value })

      }
        

    onChangepostalcode(Value) {
        this.setState({ postalcode: Value.target.value })

    }
    onChangemajorinter1(Value) {
        this.setState({ majorinersection1: Value.target.value })

    }
    onChangemajorinter2(Value) {
        this.setState({ majorintersection2: Value.target.value })

    }
    onChangeemergencycontactphone(Value) {
 

   this.setState({ emergencycontactphone: Value.target.value })  


}
      
  
    onChangesinno(Value) 
{
        this.setState({ sinno: Value.target.value })
    }
    onChangeextension(Value) 
{
        this.setState({ extension: Value.target.value })

    }


 onChangedob(Value) 
{

//this.setState({ dateofbirth: Value.target.value })
 var birthDate = new Date(Value.target.value);
   
	 var difference=Date.now() - birthDate.getTime(); 
	 	 
	 var  ageDate = new Date(difference); 
	 var calculatedAge=   Math.abs(ageDate.getUTCFullYear() - 1970);
	
    
this.setState({ dateofbirth: birthDate })
this.setState({ age: calculatedAge })
this.setState({dobstyle: {
            display: (calculatedAge < 18)  ? 'block' :'none'
        }})    

   

    }
 onChangeminsalary(Value) {
     
    this.setState({ expectedminsalary: Value.target.value })   
    


     
    }
 onChangemaxsalary(Value) {
     
    this.setState({ expectedsalary: Value.target.value })   
    


     
    }
 onChangetrans(Value) {
     
    this.setState({ transportation: Value.target.value })   
 
    }
 onChangeedu(Value) {
     
    this.setState({ education: Value.target.value })   
 
    }
 onChangelegal(Value) {
     
    this.setState({ legalquestions: Value.target.value })   
 
    }
onChangepositionheld1(Value) {
     
    this.setState({ positionheld1: Value.target.value })   
 
    }
onChangepositionheld2(Value) {
     
    this.setState({ positionheld2: Value.target.value })   
 
    }
onChangepositionheld3(Value) {
     
    this.setState({ positionheld3: Value.target.value })   
 
    }
onChangesalary1(Value) {
 
         this.setState({ salary1: Value.target.value})
}
  
     

 
   
onChangesalary2(Value) {
     
    this.setState({ salary2: Value.target.value })   
 
    }
onChangesalary3(Value) {
     
    this.setState({ salary3: Value.target.value })   
 
    }
onChangemanager1(Value) {
     
    this.setState({ manager1: Value.target.value })   
 
    }
onChangemanager2(Value) {
     
    this.setState({ manager2: Value.target.value })   
 
    }
onChangemanager3(Value) {
     
    this.setState({ manager3: Value.target.value })   
 
    }
onChangephone1(Value) {
     
    this.setState({ phone1: Value.target.value })   
 
    }
onChangephone2(Value) {
     
    this.setState({ phone2: Value.target.value })   
 
    }
onChangephone3(Value) {
     
    this.setState({ phone3: Value.target.value })   
 
    }
onChangereasonforleaving1(Value) {
     
    this.setState({ reasonforleaving1: Value.target.value })   
 
    }
onChangereasonforleaving2(Value) {
     
    this.setState({ reasonforleaving2: Value.target.value })   
 
    }
onChangereasonforleaving3(Value) {
     
    this.setState({ reasonforleaving3: Value.target.value })   
 
    }
onChangevstatus(Value) {

      this.setState({ vaccinestatus: Value.target.value })
this.setState({ vaccinestyle: {
            display: (Value.target.value == '') ? 'block' :'none'
        }})


        
    }





 
   



    SaveApplicant() 


{

if(this.state.applicantname=="" || this.state.EMail == "" || this.state.password == "" || 
 this.state.dateofbirth == "" || this.state.gender == "" )  
{
    Swal.fire({  
        position: "bottom",
        icon: 'error',  
        title: 'Oops...',  
        text: 'Fields are empty!'  
      });
    return false;

}



if (this.state.companyname1 =="" ||
    this.state.companyactive1 =="" ||
    this.state.phone1 =="" 
    ) {
    Swal.fire({  
        position: "bottom",
        icon: 'error',  
        title: 'Oops...',  
        text: 'Fill up Atleast one company details !'  
      });
    return false;

}
        var topost = {
            "permit": this.state.permit,
            "safetyshoes": this.state.safetyshoes,
            "safetyglasses": this.state.safetyglasses,
            "hardhat": this.state.hardhat,
            "safetyvest":this.state.safetyvest,
            "safetygloves": this.state.safetygloves,
            "dateofbirth": this.state.dateofbirth,
            
            "HostCompanyId":CompanyId,
            "applicantid": Date.now().toString(36) + Math.random().toString(36).substring(11),
            "applicantname": this.state.applicantname,
            "email": this.state.EMail,
            "password": this.state.password,
            "salutation": this.state.salutation,
            "age":  this.state.age,
            "gender": this.state.gender,
            "phone": this.state.phone,
            "extension": this.state.extension,
            "street": this.state.street,
            "city": this.state.city,
            "province": this.state.province,
            "postalcode": this.state.postalcode,
            "sinno": this.state.sinno,
            "emergencyphone": this.state.emergencycontactphone,
            "vaccinestatus":this.state.vaccinestatus,
            "drivinglicence": 22,
            "companycode": 33,
            "companysin": "companysin ",
            "code": "code ",
            "termsofservice": this.state.termsofservice,
            "acknowledgement": this.state.acknowledgement,
            "majorinersection1": this.state.majorinersection1,
            "majorintersection2": this.state.majorintersection2,
            "contactphone": this.state.phone,

            "expectedminsalary": this.state.expectedminsalary,
			"expectedsalary": this.state.expectedsalary,
			"transportation":this.state.transportation,
			"positionapplying":this.state.positionapplying.join(", "),
			"preferredshifts":this.state.preferredshifts.join(", "),
            "preferreddayofweek":this.state.preferreddayofweek.join(", "),
			"education":this.state.education,

			"companyid1" : this.state.companyid1 ,
			"companyname1" : this.state.companyname1 ,
			"companyactive1" : this.state.companyactive1 ,
			"companyaddress1" : this.state.companyaddress1 ,
			"companyphone1" : this.state.companyphone1 ,

			"companyid2" : this.state.companyid2 ,
			"companyname2" : this.state.companyname2 ,
			"companyactive2" : this.state.companyactive2 ,
			"companyaddress2" : this.state.companyaddress2 ,
			"companyphone2" : this.state.companyphone2 ,

			"companyid3" : this.state.companyid3 ,
			"companyname3" : this.state.companyname3 ,
			"companyactive3" : this.state.companyactive3 ,
			"companyaddress3" : this.state.companyaddress3 ,
			"companyphone3" :this.state.companyphone3 ,
"positionheld1" : this.state.positionheld1,
			"salary1" : this.state.salary1,
			"joinedon1" : "10/mar/2020" ,
			"lefton1" : "10/mar/2020" ,
			"manager1" : this.state.manager1, 
			"phone1" : this.state.phone1,
			"reasonforleaving1" : this.state.reasonforleaving1, 
			"contactemployer1" :  this.state.contactemployer1, 
"positionheld2" :this.state.positionheld2 ,
			"salary2" : this.state.salary2,
			"joinedon2" : "10/mar/2020" ,
			"lefton2" : "10/mar/2020" ,
			"manager2" : this.state.manager2, 
			"phone2" :  this.state.phone2,
			"reasonforleaving2" :this.state.reasonforleaving2, 
			"contactemployer2" : this.state.contactemployer2, 

			"positionheld3" : this.state.positionheld3 ,
			"salary3" :this.state.salary3,
			"joinedon3" : "10/mar/2020" ,
			"lefton3" : "10/mar/2020" ,
			"manager3" : this.state.manager3, 
			"phone3" : this.state.phone3,
			"reasonforleaving3" : this.state.reasonforleaving3, 
			"contactemployer3" :this.state.contactemployer3,
"skills":"skills",
			"safetyquestions": "safety",
			
			"legalquestions": this.state.legalquestions

			
	
			

        }

	

 
		console.log(JSON.stringify(topost));
        

        axios.post('https://dev.connectjob.ca/saveapplicant.php', JSON.stringify(topost)).then(response => {
            console.log(response);
            if (response.data.Error && response.data.Error.length > 0) {
                Swal.fire({  
                    position: "bottom",
                    icon: 'error',  
                    title: 'Oops...',  
                    text: 'Failed to save!'  
                  });
            } else {
                Swal.fire({  
                    title: 'Success',  
                    type: 'success',  
                    position: "bottom",
                    text: 'Application has been saved.',  
                  });              }

        })

    }


    render() {


 const { errors } = this.state;

        //    console.log("Host URL"+process.env.PUBLIC_URL);

        return (




            <div class="row" style={{ margin: 20 }}>
                <div class="col-md-12 ">

                    <form class="form-horizontal" role="form">
                        <h1 style={{ textAlign: "center" }}>Applicant Registration</h1>



                        <div class="form-group">
                            <div class="col-sm-4">
                                <label for="Email">Email</label>
                                <input onChange=
                                    {this.onChangeMail.bind(this)} type="email" id="email"  name="email" placeholder="You will use your email address to login" class="form-control" />
 <label style={this.state.emailstyle} class="colors" >Please Enter a valid Email</label>
                              
                                    
                            </div>
                            <div class="col-sm-4">
                                <label for="Password">Password</label>
                                <input onChange=
                                    {this.onChangepassword.bind(this)} type="password" id="password" name="password" placeholder="7 characters minimum" class="form-control" />
<label style={this.state.pwdstyle} class="colors" >Password must be at least 7 characters long!</label>
                            </div>
                            <div class="col-sm-4">
                                <label for="Confirm Password">Confirm Password</label>
                                <input onChange=
                                    {this.onChangecpassword.bind(this)} type="password" name="password" id="confirmpassword" class="form-control" />
<label style={this.state.cpwdstyle} class="colors" >Password did not match</label>
                            </div>
                        </div>
                        <hr />

                        <div class="form-group">
                            <div class="col-sm-3">
                                <label for="Salutation">Salutation</label>
                                <input onChange=
                                    {this.onChangesalutation.bind(this)} type="text" name="Salutation" id="Salutation" class="form-control" />
<label style={this.state.salustyle} class="colors" >Please salutaion cannot be empty</label>
                           
 </div>
                            <div class="col-sm-3">
                                <label for="ApplicantName">Applicant Name</label>
                                <input onChange=
                                    {this.onChangeApplicantname.bind(this)}  type="text" name="ApplicantName" id="ApplicantName" class="form-control" />
                              <label style={this.state.namestyle} class="colors" >Please Enter the applicant Name</label>
                            </div>
                            <div class="col-sm-3">
                                <label for="DateofBirth">Date Of Birth <span style={{ color: '#31708f' }}>*Must be 18</span></label>
                                <input onChange=
                                    {this.onChangedob.bind(this)} type="date" name="DateofBirth" id="DateofBirth" class="form-control" />
<label style={this.state.dobstyle} class="colors" >You Are Not Eligible.</label>
                          
  </div>
                            <div class="col-sm-3">
                                <label for="gender">Gender</label>
                                <select onChange=
                                    {this.onChangegender.bind(this)} id="gender" placeholder="Select A Gender" class="form-control"  >
                                    <option value="">Select A Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>

                                </select>
  <label style={this.state.genderstyle} class="colors" >Please Select A Gender</label>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="col-sm-3">
                                <label for="StreetAddr">Street Address</label>
                                <input onChange=
                                    {this.onChangestreet.bind(this)} type="text" name="StreetAddr" id="StreetAddr" class="form-control" />
 <label style={this.state.streetstyle} class="colors" >Please Enter the street Address</label>
                            </div>
                            <div class="col-sm-3">
                                <label for="city">City</label>
                                <input onChange=
                                    {this.onChangecity.bind(this)} type="text" name="city" id="city" class="form-control" />
 <label style={this.state.citystyle} class="colors" >Please Enter the City</label>
                            </div>
                            <div class="col-sm-3">
                                <label for="Province">Province</label>
                                <select onChange=
                                    {this.onChangeprovince.bind(this)} id="Province" placeholder="Select A Province" class="form-control" >
                                    <option value="">Select A Province</option>
                                    <option value="Ontario">Ontario</option>
                                    <option value="Quebec">Quebec</option>
                                    <option value="Nova_Scotia<">Nova Scotia</option>
                                    <option value="New_Brunswick">New Brunswick</option>
                                    <option value="Manitoba">Manitoba</option>
                                    <option value="British_Columbia">British Columbia</option>
                                    <option value="Prince_Edward_Island">Prince Edward Island</option>
                                    <option value="Saskatchewan">Saskatchewan</option>
                                    <option value="Alberta">Alberta</option>
                                    <option value="Newfoundland_and_Labrador">Newfoundland and Labrador</option>
                                    <option value="Northwest_Territories">Northwest Territories</option>
                                    <option value="Yukon">Yukon</option>
                                    <option value="Nunavut">Nunavut</option>

                                </select>
<label style={this.state.provincestyle} class="colors" >Please Select A Province</label>
                            </div>
                            <div class="col-sm-3">
                                <label for="PostalCode">Postal Code</label>
                                <input onChange=
                                    {this.onChangepostalcode.bind(this)} type="text" name="PostalCode" id="PostalCode" class="form-control" />
<label style={this.state.postalstyle} class="colors" >Please Enter the postal code</label>
                            </div>
                        </div>
                        <hr />

                        <div class="form-group">
                            <div class="col-sm-3">
                                <label for="PhoneNumber">Phone Number</label>
                                <input onChange=
                                    {this.onChangephone.bind(this)} type="tel" name="PhoneNumber" id="PhoneNumber" class="form-control" placeholder="(905) 333 4535" />
                           <label style={this.state.phonestyle} class="colors" >Please Enter a valid Phone Number</label>
 </div>
                            <div class="col-sm-3">
                                <label for="Extension">Extension</label>
                                <input onChange=
                                    {this.onChangeextension.bind(this)} type="text" name="Extension" id="Extension" class="form-control" />
<label style={this.state.extstyle} class="colors" >Please Enter the Extension</label>
                            </div>
                            <div class="col-sm-3">
                                <label for="SIN">SIN(Optional)</label>
                                <input onChange=
                                    {this.onChangesinno.bind(this)} type="text" name="SIN" id="SIN" class="form-control" />
                            </div>
  <div class="col-sm-3">
                                <label for="EmergencyContactName">Emergency Contact Name</label>
                                <input type="text" name="EmergencyContactName" id="EmergencyContactName" class="form-control" />
                            </div>

                        </div>
                        <div class="form-group">
                          
                            <div class="col-sm-3">
                                <label for="EmergencyContactNumber">Emergency Contact Phone Number</label>
                                <input onChange=
                                    {this.onChangeemergencycontactphone.bind(this)} type="tel" name="EmergencyContactNumber" id="EmergencyContactNumber" class="form-control" placeholder="(905) 333 4535" />
 <label style={this.state.emergencyphonestyle} class="colors" >Please Enter a valid Contact Phone Number</label>

                           
 </div>
 <div class="col-sm-3">
                                <label for="MajorIntersection1">Major Intersection 1</label>
                                <input onChange=
                                    {this.onChangemajorinter1.bind(this)} type="text" name="MajorIntersection1" id="MajorIntersection1" class="form-control" />
 <label style={this.state.major1style} class="colors" >Please Enter the field</label>
                            </div>
<div class="col-sm-3">
                                <label for="MajorIntersection2">Major Intersection 2</label>
                                <input onChange=
                                    {this.onChangemajorinter2.bind(this)} type="text" name="MajorIntersection2" id="MajorIntersection2" class="form-control" />
 <label style={this.state.major2style} class="colors" >Please Enter the field</label>
                            </div>
<div class="col-sm-3">
                                <label for="vstatus">Vaccination Status</label>
                                <select onChange=
                                    {this.onChangevstatus.bind(this)} id="vstatus" placeholder="Select The Vaccine Status" class="form-control"  >
                                    <option value="">Select The Vaccine Status</option>
                                    <option value="fullBooster">Fully Vaccinated + Booster</option>
                                    <option value="full">Fully Vaccinated</option>
 <option value="1dose">1 Dose</option>
 <option value="0dose">0 Dose</option>



                                </select>
 <h5 style={{ color: '#4c4cca', font: 12 }}> Documentation Should be provided upon employers request.</h5>

 
                            </div>
                         
                        </div>


                        <hr />
                        <div class="form-group">
                            <div class="col-sm-4">
                                <label for="Positions">Positions Applying For</label><br />
                                <div class="positions">
                                    <input type="checkbox" name="positions[]" value="Administrative Support"  onChange={this.handleInputChange}/> Administrative Support<br />
                                    <input type="checkbox" name="positions[]" value="Clerical"  onChange={this.handleInputChange}/> Clerical<br />
                                    <input type="checkbox" name="positions[]" value="General Office Support"  onChange={this.handleInputChange}/> General Office Support<br />
                                    <input type="checkbox" name="positions[]" value="Human Resource Professionals" onChange={this.handleInputChange}/> Human Resource Professionals<br />
                                    <input type="checkbox" name="positions[]" value="Marketing" onChange={this.handleInputChange}/> Marketing<br />
                                    <input type="checkbox" name="positions[]" value="Reception" onChange={this.handleInputChange}/> Reception<br />
                                    <input type="checkbox" name="positions[]" value="Call Center" onChange={this.handleInputChange}/> Call Center<br />
                                    <input type="checkbox" name="positions[]" value="Telemarketing" onChange={this.handleInputChange}/> Telemarketing
                                <br />
                                    <input type="checkbox" name="positions[]" value="Information Technology" onChange={this.handleInputChange}/> Information Technology<br />
                                    <input type="checkbox" name="positions[]" value="Hospitality" onChange={this.handleInputChange}/> Hospitality<br />
                                    <input type="checkbox" name="positions[]" value="Accounting" onChange={this.handleInputChange}/> Accounting<br />
                                    <input type="checkbox" name="positions[]" value="Bookkeeping" onChange={this.handleInputChange}/> Bookkeeping<br />
                                    <input type="checkbox" name="positions[]" value="Collections" onChange={this.handleInputChange}/> Collections<br />
                                    <input type="checkbox" name="positions[]" value="Payroll Processing and Support" onChange={this.handleInputChange}/> Payroll Processing and Support<br />
                                    <input type="checkbox" name="positions[]" value="File Clerks" onChange={this.handleInputChange}/> File Clerks<br />
                                    <input type="checkbox" name="positions[]" value="Legal Secretary" onChange={this.handleInputChange}/>Legal Secretary<br />
                                    <input type="checkbox" name="positions[]" value="Paralegal" onChange={this.handleInputChange}/>Paralegal<br />
                                    <input type="checkbox" name="positions[]" value="RN" onChange={this.handleInputChange}/> RN<br />
                                    <input type="checkbox" name="positions[]" value="RPN" onChange={this.handleInputChange}/>RPN<br />
                                    <input type="checkbox" name="positions[]" value="PSW" onChange={this.handleInputChange}/> PSW<br />
                                    <input type="checkbox" name="positions[]" value="General Labour" onChange={this.handleInputChange}/>General Labour<br />
                                    <input type="checkbox" name="positions[]" value="Warehouse" onChange={this.handleInputChange}/>Warehouse<br />
                                    <input type="checkbox" name="positions[]" value="Loading/Unloading" onChange={this.handleInputChange}/> Loading/Unloading<br />
                                    <input type="checkbox" name="positions[]" value="Packing" onChange={this.handleInputChange}/>Packing<br />
                                    <input type="checkbox" name="positions[]" value="Shipping/Receiving" onChange={this.handleInputChange}/> Shipping/Receiving<br />
                                    <input type="checkbox" name="positions[]" value="Inventory" onChange={this.handleInputChange}/> Inventory<br />
                                    <input type="checkbox" name="positions[]" value="Assembly line" onChange={this.handleInputChange}/>Assembly line<br />
                                    <input type="checkbox" name="positions[]" value="Sanitation" onChange={this.handleInputChange}/>Sanitation<br />
                                    <input type="checkbox" name="positions[]" value="Order Picker" onChange={this.handleInputChange}/>Order Picker<br />
                                    <input type="checkbox" name="positions[]" value="AZ, DZ, G Driver" onChange={this.handleInputChange}/>AZ, DZ, G Driver<br />
                                    <input type="checkbox" name="positions[]" value="Forklift Operators" onChange={this.handleInputChange}/>Forklift Operators<br />
                                    <input type="checkbox" name="positions[]" value="Machine Operators" onChange={this.handleInputChange}/> Machine Operators<br />
                                    <input type="checkbox" name="positions[]" value="CNC Programmers/Operators" onChange={this.handleInputChange}/> CNC Programmers/Operators<br />
                                    <input type="checkbox" name="positions[]" value="Welders" onChange={this.handleInputChange}/>Welders<br />
                                    <input type="checkbox" name="positions[]" value="Quality control" onChange={this.handleInputChange}/>Quality control<br />
                                    <input type="checkbox" name="positions[]" value="Other" onChange={this.handleInputChange}/>Other<br />
                                </div>
                            </div>
                            <div class="col-sm-8">
                                <h5 style={{ color: '#4c4cca', font: 14 }}> Expected Earnings (Hourly)</h5>
                                <div class="col-sm-3">
                                    <label for="minimum">Minimum</label>
                                    <input onChange=
                                    {this.onChangeminsalary.bind(this)}  type="text" name="minimum" id="minimum" placeholder="ie. $20.00/hour" class="form-control" />
                                </div>
                                <div class="col-sm-3">
                                    <label for="Expected">Expected</label>
                                    <input onChange=
                                    {this.onChangemaxsalary.bind(this)} type="text" name="Expected" id="Expected" placeholder="ie. $20.00/hour" class="form-control" />
                                </div>
                                <div class="col-sm-6">
                                    <label for="Timings">Preferred Timings</label><br />
                                    <input type="checkbox" name="timings[]" value="Mornings"  onChange={this.handletimingsChange}/> Mornings<br />
                                    <input type="checkbox" name="timings[]" value="Afternoons" onChange={this.handletimingsChange} /> Afternoons<br />
                                    <input type="checkbox" name="timings[]" value="Evenings" onChange={this.handletimingsChange}/> Evenings<br />
                                    <input type="checkbox" name="timings[]" value="Nights" onChange={this.handletimingsChange}/> Nights<br />

                                </div>
                                <br />

                                <div class="col-sm-3">

                                    <input type="checkbox" name="weekday[]" value="Sunday"  onChange={this.handleweekdayChange}/> Sunday<br />
                                    <input type="checkbox" name="weekday[]" value="Monday" onChange={this.handleweekdayChange}/> Monday<br />
                                    <input type="checkbox" name="weekday[]" value="Tuesday" onChange={this.handleweekdayChange}/> Tuesday<br />
                                    <input type="checkbox" name="weekday[]" value="Wednesday" onChange={this.handleweekdayChange}/> Wednesday<br />


                                </div>
                                <div class="col-sm-3">

                                    <input type="checkbox" name="weekday[]" value="Thursday" onChange={this.handleweekdayChange} /> Thursday<br />
                                    <input type="checkbox" name="weekday[]" value="Friday" onChange={this.handleweekdayChange}/> Friday<br />
                                    <input type="checkbox" name="weekday[]" value="Saturday" onChange={this.handleweekdayChange}/> Saturday<br />



                                </div>
                                <div class="col-sm-6">
                                    <label for="Transportation">Method Of Transportation</label>
                                    <input onChange=
                                    {this.onChangetrans.bind(this)} type="text" name="Transportation" id="Transportation" class="form-control" />
                                </div>
                            </div>

                        </div>
                        <div class="form-group">
                            <div class="col-sm-4">
 <fieldset className="sin"  >
                                <label>Question applies to those that do not have a SIN: Do you have a valid work permit, permitting you to legally work in Canada?</label>
                                <input type="radio" name="sin" onChange={this.handleChangepermit} id="Yes"  /> Yes &nbsp;&nbsp;
                            <input type="radio" name="sin" onChange={this.handleChangepermit} id="No"  /> No<br />
</fieldset>
                                <fieldset>
                                <label style={{ color: '#4c4cca', font: 14 }}>If applying for manufacturing positions (general labour etc.) do you have the following safety equipment:</label>
                                <label>Safety Shoes</label>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;   
                                <input type="radio" name="safetyshoes" onChange={this.handleChangesafetyshoes} />  Yes &nbsp;&nbsp;
                                <input type="radio" name="safetyshoes" onChange={this.handleChangesafetyshoes} /> No<br />
                                </fieldset>

                                <fieldset>
                                <label>Safety Glasses</label>&nbsp;&nbsp;&nbsp;    
                                <input type="radio" name="safetyglasses" onChange={this.handleChangesafetyglass} />  Yes &nbsp;&nbsp;
                                <input type="radio" name="safetyglasses" onChange={this.handleChangesafetyglass}/> No<br />
                                </fieldset>

                                <fieldset>

                                <label>Hard Hat</label> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                                <input type="radio" name="hardhat"  onChange={this.handleChangehardhat} value="true" id="Yes" />  Yes &nbsp;&nbsp;
                                <input type="radio" name="hardhat" onChange={this.handleChangehardhat} value="false" id="No" checked /> No<br />
                                </fieldset>
                                <fieldset>

                                <label>Safety vest</label>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                <input type="radio" name="safetyvest" onChange={this.handleChangesafetyvest} name="safety_vest" value="true" id="Yes" />  Yes &nbsp;&nbsp;
                                <input type="radio" name="safetyvest" onChange={this.handleChangesafetyvest} name="safety_vest" value="false" id="No" checked /> No<br />
                                </fieldset>

                                <fieldset>

                                <label>Safety gloves</label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="radio" name="safetygloves"  onChange={this.handleChangesafetygloves} name="safety_gloves" value="true" id="Yes" />  Yes &nbsp;&nbsp;
                                <input type="radio"  name="safetygloves" onChange={this.handleChangesafetygloves} name="safety_gloves" value="false" id="No" checked /> No<br />
                                </fieldset>



                            </div>
                            <div class="col-sm-8">
                                <label for="Education">Education</label>
                                <textarea onChange=
                                    {this.onChangeedu.bind(this)} type="text" name="Education" id="Education" class="form-control" placeholder="Enter Your Education Details -Max 300 Characters"></textarea>
                            </div>

                        </div>
                        <div class="bg" >
                            <h4 style={{ color: '#4c4cca' }}>Employment History (List Most Recent Employer First) * Atleast One Employment Record is required</h4>
                            <div class="form-group" >
                                <div class="col-sm-4">
                                    <div class="circle">1</div><br />

                                    <label for="EmployerName">Employer Name</label>
                                    <input type="text" name="EmployerName1" id="EmployerName1" class="form-control" onChange=
                                    {this.onChangecompanyname1.bind(this)}/><br />
                                    <label for="PositionHeld">Position(s) Held</label>
                                    <input onChange=
                                    {this.onChangepositionheld1.bind(this)} type="text" name="PositionHeld1" id="PositionHeld1" class="form-control" /><br />
                                    <label for="Salary">Salary</label>
                                    <input onkeypress={this.handlenumber(this)} onChange=
                                    {this.onChangesalary1.bind(this)}    type="text" name="Salary1" id="Salary" class="form-control" /><br />
                                    <label for="Employed From / To">Employed From / To</label>
                                    <input type="text" name="Employed_From_To1" id="Employed_From_To1" class="form-control"  onChange=
                                    {this.onChangecompanyactive1.bind(this)} /><br />
                                    <label for="ManagerName">Manager's Name</label>
                                    <input onChange=
                                    {this.onChangemanager1.bind(this)} type="text" name="ManagerName1" id="ManagerName1" class="form-control" /><br />
                                    <label for="Contact Number">Contact Number</label>
                                    <input onChange=
                                    {this.onChangephone1.bind(this)} type="text" name="ContactNumber1" id="ContactNumber1" class="form-control" /><br />
                                    <label for="Leaving">Reason For Leaving</label>
                                    <textarea onChange=
                                    {this.onChangereasonforleaving1.bind(this)} type="text" name="Leaving1" id="Leaving1" class="form-control" placeholder="Max 75 Characters"></textarea><br />
                                    <input  onChange={this.handlecontactemp1} defaultChecked={this.state.contactemployer1} type="checkbox" value="" /> May We Contact The Employer?<br />


                                </div>
                                <div class="col-sm-4">
                                    <div class="circle">2</div><br />

                                    <label for="EmployerName">Employer Name</label>
                                    <input type="text" name="EmployerName2" id="EmployerName2" class="form-control" onChange=
                                    {this.onChangecompanyname2.bind(this)} /><br />
                                    <label for="PositionHeld">Position(s) Held</label>
                                    <input onChange=
                                    {this.onChangepositionheld2.bind(this)} type="text" name="PositionHeld2" id="PositionHeld2" class="form-control" /><br />
                                    <label for="Salary">Salary</label>
                                    <input onChange=
                                    {this.onChangesalary2.bind(this)} type="text" name="Salary2" id="Salary2" class="form-control" /><br />
                                    <label for="Employed From / To">Employed From / To</label>
                                    <input type="text" name="Employed_From_To2" id="Employed_From_To2" class="form-control"   onChange=
                                    {this.onChangecompanyactive2.bind(this)} /><br />
                                    <label for="ManagerName">Manager's Name</label>
                                    <input onChange=
                                    {this.onChangemanager2.bind(this)} type="text" name="ManagerName2" id="ManagerName2" class="form-control" /><br />
                                    <label for="Contact Number">Contact Number</label>
                                    <input onChange=
                                    {this.onChangephone2.bind(this)} type="text" name="ContactNumber2" id="ContactNumber2" class="form-control" /><br />
                                    <label for="Leaving">Reason For Leaving</label>
                                    <textarea onChange=
                                    {this.onChangereasonforleaving2.bind(this)} type="text" name="Leaving2" id="Leaving2" class="form-control" placeholder="Max 75 Characters"></textarea><br />
                                    <input   onChange={this.handlecontactemp2} defaultChecked={this.state.contactemployer2} type="checkbox" value="" />May We Contact The Employer?<br />


                                </div>
                                <div class="col-sm-4">
                                    <div class="circle">3</div><br />

                                    <label for="EmployerName">Employer Name</label>
                                    <input type="text" name="EmployerName3" id="EmployerName3" class="form-control"  onChange=
                                    {this.onChangecompanyname3.bind(this)}/><br />
                                    <label for="PositionHeld">Position(s) Held</label>
                                    <input onChange=
                                    {this.onChangepositionheld3.bind(this)} type="text" name="PositionHeld3" id="PositionHeld3" class="form-control" /><br />
                                    <label for="Salary">Salary</label>
                                    <input onChange=
                                    {this.onChangesalary3.bind(this)} type="text" name="Salary3" id="Salary3" class="form-control" /><br />
                                    <label for="Employed From / To">Employed From / To</label>
                                    <input type="text" name="Employed_From_To3" id="Employed_From_To3" class="form-control"   onChange=
                                    {this.onChangecompanyactive3.bind(this)} /><br />
                                    <label for="ManagerName">Manager's Name</label>
                                    <input onChange=
                                    {this.onChangemanager3.bind(this)} type="text" name="ManagerName3" id="ManagerName3" class="form-control" /><br />
                                    <label for="Contact Number">Contact Number</label>
                                    <input onChange=
                                    {this.onChangephone3.bind(this)} type="text" name="ContactNumber3" id="ContactNumber3" class="form-control" /><br />
                                    <label for="Leaving">Reason For Leaving</label>
                                    <textarea onChange=
                                    {this.onChangereasonforleaving3.bind(this)} type="text" name="Leaving3" id="Leaving3" class="form-control" placeholder="Max 75 Characters"></textarea><br />
                                    <input  onChange={this.handlecontactemp3} defaultChecked={this.state.contactemployer3} type="checkbox" value="" />May We Contact The Employer?<br />


                                </div>

                            </div>

                        </div>
                        <div class="form-group">
                            <div class="col-sm-12">
                                <input onChange={this.handleChange} defaultChecked={this.state.acknowledgement} type="checkbox" value="" />
                                  I FULLY UNDERSTAND AND ACKNOWLEDGE THAT THE INFORMATION GIVEN ABOVE BY ME IS COMPLETE AND TRUE ANY FALSE STATEMENTS, MISREPRESENTATIONS MADE ON THIS APPLICANTION OR DURING HIRING PROCESS MAY DISQUALIFY ME FOR EMPLOYMENT. IN TERMS OF MY EMPLOYMENT, I AGREE TO CONFORM BY THE RULES, REGULATIONS, AND CONDITIONS OF {this.state.HostCompanyName}. I FURTHER UNDERSTAND THAT THIS APPLICATION IS NOT CONTRACT OF EMPLOYMENT. I ALSO UNDERSTAND THAT THIS APPLICATION MAY BE USED/SHARED WITH OTHER {this.state.HostCompanyName}. PERSONNEL. I ALSO UNDERSTAND THAT FURTHER PROOF OF CERTIFICATIONS MAY BE REQUIRED AFTER BEING HIRED.<br />
                                <br />  
                                <input onChange={this.handleCheck} defaultChecked={this.state.termsofservice} type="checkbox" value="" />  I AGREE TO THE TERMS & CONDITIONS OF {this.state.HostCompanyName}<a href={this.state.HostCompanyURL} target="_blank"> Read Terms & Conditions</a>
                             <label style={this.state.termsstyle} class="colors" >Must Accept The terms & Conditions</label>
                            </div><br />
                        </div>
                        <div class="form-group">
                            <div class="col-sm-12">
                                <button type='button' onClick={this.SaveApplicant.bind(this)} class="btn btn-primary">REGISTER NOW</button>
                            </div><br />


                        </div>




                    </form>
                </div>
            </div>
        );


    }

}


export default RegisterPage;
